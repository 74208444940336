import React, { useEffect, useState } from 'react';

import { adminRights as defaultAdminRight } from 'utils/constants';
import { ACTIVATION, ADMINRIGHTS, AUTH } from 'utils/rest';

import { cleanLocalStorage } from 'containers/AuthContainer';

const { getUser, logout } = AUTH;

const UserContext = React.createContext();
const requestStates = {
  LOADING: 'loading',
  LOADED: 'loaded',
  NOT_LAUNCH: 'not_launch'
};

const { LOADING, LOADED, NOT_LAUNCH } = requestStates;

/**
 * UserProvider
 * @component
 */
const UserProvider = props => {
  const [user, setUser] = useState({});
  const [adminRights, setAdminRights] = useState({});
  const [userLocation, setUserLocation] = useState(window.location.pathname);
  const [requestState, setRequestState] = useState(LOADING);
  const [activationShopGroups, setActivationShopGroups] = useState([]);
  const fetchUser = async () => {
    try {
      const response = await getUser();
      const currentUser = response.data;
      setUser({
        ...currentUser,
        roles: currentUser.roles || []
      });

      const data = await ADMINRIGHTS.getRightsById(currentUser.id);
      setAdminRights(data?.[0]?.rights || defaultAdminRight);
      fetchActivationShopGroups();

      setRequestState(LOADED);
      return { ...currentUser, roles: currentUser.roles || [] };
    } catch (e) {
      setRequestState(NOT_LAUNCH);
      return {};
    }
  };

  const fetchActivationShopGroups = async () => {
    const data = await ACTIVATION.getActivationShopGoups();
    setActivationShopGroups(data);
  };

  useEffect(() => {
    fetchUser();
    // eslint-disable-next-line
  }, []);

  const signIn = async ({ email, password }) => {
    try {
      const data = await AUTH.login(email, password);
      return data;
    } catch (e) {
      console.error(e);
      return { success: false, error: e };
    }
  };

  const logOut = async () => {
    setUser({});
    try {
      await logout();
      cleanLocalStorage();
      setRequestState(NOT_LAUNCH);
    } catch (err) {
      if (process.env.NODE_ENV === 'development') {
        console.error('err sign out  user (userProvider)', err);
      }
    }
  };

  const value = {
    isAuthenticated: !!user.id,
    isSuperAdmin: user?.roles?.includes('SUPERADMIN'),
    isLoadingAuth: requestState === LOADING,
    logOut,
    signIn,
    fetchUser,
    user,
    userLocation,
    setUserLocation,
    adminRights,
    setAdminRights,
    activationShopGroups
  };
  return <UserContext.Provider {...props} value={value} />;
};

export default UserProvider;

export const useUser = () => {
  const context = React.useContext(UserContext);
  if (!context) throw new Error('UserContext must be in UserProvider');
  return context;
};
