import React from 'react';

import { useTheme } from 'contexts/ThemeContext';

import { Modal } from 'components/Feedback';
import {
  Container,
  StepContent,
  Typography,
  Stepper,
  Step,
  StepLabel
} from '@mui/material';

import Loading from 'components/Loading';

export const ModalStepper = props => {
  const steps = [0, 1, 2];
  const { themeColors } = useTheme();

  const getSteps = step => {
    switch (step) {
      case 0:
        return "Création de l'utilisateur";
      case 1:
        return 'Création du point de vente';
      case 2:
        return 'Association au réseau';
      default:
        return 'Unknown step';
    }
  };

  const getStepContent = step => {
    switch (step) {
      case 0:
        return 'Votre nouvel utilisateur est en cours de création, veuillez patienter quelques instants.';
      case 1:
        return 'Votre point de vente est en cours de création, veuillez patienter quelques instants.';
      case 2:
        return 'Votre point de vente est en cours de rattachement au réseau, veuillez patienter quelques instants.';
      default:
        return 'Unknown step';
    }
  };
  return (
    <Modal hideCloseButton open={true}>
      <Container>
        <Stepper
          activeStep={props.activeStep}
          orientation="vertical"
          sx={{
            '& .MuiStepLabel-label': {
              color: themeColors.themeV2.subtitleGrey,
              '&.Mui-completed': { color: themeColors.global },
              '&.Mui-active': { color: themeColors.global }
            },
            '& .MuiStepIcon-root': {
              color: themeColors.themeV2.subtitleGrey,
              '&.Mui-completed': { color: themeColors.global },
              '&.Mui-active': { color: themeColors.global }
            },
            '& .MuiStepConnector-line': {
              borderColor: themeColors.greyLight
            }
          }}
        >
          {steps.map(step => (
            <Step key={step}>
              <StepLabel>{getSteps(step)}</StepLabel>
              <StepContent>
                <Typography>{getStepContent(step)}</Typography>
                <Loading loading={true} />
              </StepContent>
            </Step>
          ))}
        </Stepper>
      </Container>
    </Modal>
  );
};
