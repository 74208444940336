import { api } from './api';

const createShop = async ({ activationShopData, insightShopData, owner }) => {
  try {
    const { data } = await api.post(`/createShop`, {
      activationShopData,
      insightShopData,
      owner
    });
    return data;
  } catch (err) {
    console.error(err);
    return [];
  }
};

export default { createShop };
