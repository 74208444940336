import React from 'react';
import PropTypes from 'prop-types';
import { Link, useLocation, withRouter } from 'react-router-dom';

import { useApp } from 'contexts/AppContext';
import { useTheme } from 'contexts/ThemeContext';
import { useUser } from 'contexts/UserContext';

import { panels } from 'utils/constants';
import Icon from 'utils/icons';

import { Text } from 'components/DataDisplay';
import Image from 'components/Image';
import { Container, Item, Row } from 'components/Layout';
import { AppBar } from 'components/Surfaces';

import ProfilNav from './ProfilNav';
import { BaseHeaderLink, NavImg, NavTab, NavTabItem } from './style';

/**
 * useLocationPanel
 * return current panel (regarding to the url)
 *
 */
const useLocationPanel = (location, setPanelColor) => {
  const [currentPanel, setCurrentPanel] = React.useState(panels.funnel);
  const { pathname } = location;
  React.useEffect(() => {
    const nextPanel = pathname
      .split('/')
      .find(onePath => Object.values(panels).includes(onePath));
    setCurrentPanel(nextPanel);
  }, [pathname]);
  React.useEffect(() => {
    // Set global color to ThemeContext
    setPanelColor(currentPanel);
  }, [currentPanel, setPanelColor]);
  return currentPanel;
};

/**
 * @component
 * @param {children} param0
 *
 */
function HeaderContainer(props) {
  const currentLocation = useLocation();
  const { location, hideOnLocation } = props;
  const { isAuthenticated, setUserLocation, isSuperAdmin } = useUser();
  const { domainConfig, isSimplify } = useApp();
  const { themeColors, toggleTheme, isThemeLight, setPanelColor } = useTheme();
  const currentPanel = useLocationPanel(location, setPanelColor);

  if (hideOnLocation.includes(currentLocation.pathname)) return <></>;

  const getPannelName = panel => {
    switch (panel) {
      case 'activation':
        return <>Media&nbsp;Plan</>;
      case 'campaign':
        return 'campagne';
      default:
        return panel;
    }
  };

  const showLlm = () => {
    return process.env.REACT_APP_LLM?.toString() === '1' && isSuperAdmin;
  };

  return (
    <AppBar>
      <Container
        style={{ backgroundColor: themeColors.primary_header }}
        // Show Header only if user is authenticated
        show={isAuthenticated || 'none'}
      >
        <Row
          spacing={0}
          style={{
            borderBottom: `8px solid ${themeColors[currentPanel] ||
              themeColors.backoffice}`
          }}
        >
          <Item
            flex
            style={{
              padding: 8,
              paddingBottom: 0
            }}
          >
            <Container>
              <Row spacing={1}>
                <Item flex>
                  <Link to={isSimplify ? '/global' : '/dashboard'}>
                    <NavImg style={{ width: 200, paddingLeft: '20px' }}>
                      {!domainConfig?.logo && !domainConfig?.darkLogo ? (
                        <Icon
                          className="logo"
                          icon="ermes_logo_noel2024"
                          attrs={{
                            fill: themeColors[currentPanel || 'funnel'],
                            width: 200
                          }}
                        />
                      ) : (
                        <Image
                          src={domainConfig.logo || domainConfig.darkLogo}
                          style={{
                            maxWidth: '130px',
                            maxHeight: '60px',
                            objectFit: 'contain'
                          }}
                        />
                      )}
                    </NavImg>
                  </Link>
                </Item>
                <Item xs>
                  <Text color={themeColors.keep_light}>Administration</Text>
                </Item>
              </Row>
            </Container>
          </Item>
          <Item
            xs
            justify={isSimplify ? 'left' : 'center'}
            style={{
              padding: 8,
              paddingBottom: 0
            }}
          >
            <NavTab>
              {Object.values(panels)
                .filter(panel => {
                  if (isSimplify) {
                    return panel === 'global';
                  }
                  return panel;
                })
                .map(panel => {
                  if (panel === 'llm' && !showLlm()) {
                    return <></>;
                  }

                  return (
                    panel && (
                      <NavTabItem
                        id={panel}
                        key={panel}
                        isFocused={panel === currentPanel}
                        onClick={() => {
                          setUserLocation(`/${panel}`);
                        }}
                        color={panel === currentPanel && themeColors[panel]}
                      >
                        <BaseHeaderLink
                          to={`/${panel}`}
                          className="nav-tab-link"
                          isActive={panel === currentPanel}
                        >
                          <Icon icon={panel} />
                          <Text color={themeColors.keep_light} uppercase>
                            {getPannelName(panel)}
                          </Text>
                        </BaseHeaderLink>
                      </NavTabItem>
                    )
                  );
                })}
            </NavTab>
          </Item>
          <Item
            flex
            style={{
              justifyContent: 'flex-end',
              padding: 8,
              paddingBottom: 0
            }}
          >
            <ProfilNav toggleTheme={toggleTheme} isLightMode={isThemeLight} />
          </Item>
        </Row>
      </Container>
    </AppBar>
  );
}

HeaderContainer.propTypes = {
  hideOnLocation: PropTypes.arrayOf(PropTypes.string).isRequired,
  location: PropTypes.shape({
    key: PropTypes.string,
    pathname: PropTypes.string.isRequired,
    search: PropTypes.string,
    hash: PropTypes.string,
    state: PropTypes.shape({})
  }).isRequired
};

export default withRouter(HeaderContainer);
