import { api } from './api';

const duplicatePal = async ({
  activationShopIds,
  filters,
  campaignConfigIds
}) => {
  try {
    const { data } = await api.post(
      '/v2/activation/campaignConfigs/duplicatePal/',
      {
        activationShopIds,
        filters,
        campaignConfigIds
      }
    );
    return data;
  } catch (e) {
    console.error(e);
    return null;
  }
};

export default { duplicatePal };
