import React, { useRef, useState } from 'react';
import { Room } from '@mui/icons-material';
import { OutlinedInput, Paper, Popper } from '@mui/material';
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng
} from 'react-places-autocomplete';

import { useTheme } from 'contexts/ThemeContext';

import { Divider, Text } from 'components/DataDisplay';
import { Item, Row } from 'components/Layout';

export const LocationSearchInput = ({
  onPlaceSelected,
  title,
  required = false,
  error,
  color
}) => {
  const { themeColors } = useTheme();
  const [address, setAdress] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);
  const inputRef = useRef(null);

  const handleChange = value => {
    setAnchorEl(inputRef.current);
    setAdress(value);
  };

  const handleSelect = value => {
    handleChange(value);
    geocodeByAddress(value)
      .then(results => {
        getLatLng(results[0]).then(({ lat, lng }) => {
          onPlaceSelected({ lat, lng, address: value });
        });
      })
      .catch(e => console.error('Error', e));
  };

  return (
    <PlacesAutocomplete
      value={address}
      onChange={handleChange}
      onSelect={handleSelect}
    >
      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => {
        return (
          <>
            {title && (
              <Row spacing={2} justify="flex-start" alignItems="center">
                <Item justify="flex-start" flex>
                  <Text noWrap color={error ? 'error' : null} fontSize="14px">
                    {title === '&nbsp;' ? <>&nbsp;</> : title}
                    {required && '*'}
                  </Text>
                </Item>
              </Row>
            )}
            <OutlinedInput
              {...getInputProps({})}
              sx={{
                borderRadius: '5px',
                height: '44px',
                width: '100%',
                backgroundColor: themeColors.primary,
                border: 'none',
                padding: '1em',
                fontSize: '12px',
                outline: 'none',
                '& .MuiOutlinedInput-notchedOutline': {
                  borderColor: `${error ? '#d32f2f' : color} !important`
                }
              }}
              ref={inputRef}
            />
            <Popper
              open={suggestions.length > 0}
              anchorEl={anchorEl}
              placement="bottom-start"
              disablePortal
              style={{ zIndex: 200 }}
            >
              <Paper
                style={{
                  width: inputRef.current?.offsetWidth || 0,
                  padding: '10px'
                }}
              >
                {loading && <Text>Loading...</Text>}
                {suggestions.map(suggestion => {
                  const className = suggestion.active
                    ? 'suggestion-item--active'
                    : 'suggestion-item';
                  const style = suggestion.active
                    ? {
                        backgroundColor: themeColors.tertiary,
                        cursor: 'pointer'
                      }
                    : {
                        backgroundColor: themeColors.secondary,
                        cursor: 'pointer'
                      };
                  return (
                    <Row spacing={0}>
                      <Item
                        justify="flex-start"
                        alignItems="center"
                        {...getSuggestionItemProps(suggestion, {
                          className,
                          style
                        })}
                      >
                        <Room size="small" />
                        <Text>{suggestion.description}</Text>
                      </Item>
                      <Divider style={{ width: '100%', height: '1px' }} />
                    </Row>
                  );
                })}
              </Paper>
            </Popper>
          </>
        );
      }}
    </PlacesAutocomplete>
  );
};

export default LocationSearchInput;
